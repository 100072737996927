import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatter'
})
export class FormatterPipe implements PipeTransform {
  transform(value: any, formatType: string): string {
    //if (!value) return '';

    switch (formatType) {
      case 'currency':
        return this.formatCurrency(value);
      case 'date':
        return this.formatDate(value);
      case 'text':
        return this.formatText(value);
      default:
        if(!value) return '';
        return value;
    }
  }

  // Formater les montants avec des espaces comme séparateurs
  private formatCurrency(value: number): string {
    if (!value) return '0';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  // Formater les dates avec Moment.js ou tout autre outil
  private formatDate(value: string | Date): string {
    if (!value) return '';
    return value.toString();
    // return moment(value).format('DD/MM/YYYY');  // Adapter le format ici si besoin
  }

  // Exemple de traitement de texte (ici on pourrait manipuler le texte si besoin)
  private formatText(value: string): string {
    if(!value) return '';
    value = value + '';
    return value.trim();  // On pourrait ajouter d'autres traitements ici
  }
}
/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Utilisateur } from 'app/models/utilisateur.model';

let user: Utilisateur;
if (localStorage.getItem('user'))
    user = JSON.parse(localStorage.getItem('user'));

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        roles: 'ADMIN,USER,ENTREPRISE,INVITE'
    },
    {
        id: 'declarations',
        title: 'Déclaration',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/declarations',
        roles: 'USER,ADMIN,ENTREPRISE'
    },
    {
        id: 'correspondances',
        title: 'Correspondances',
        type: 'basic',
        icon: 'iconsmind:university',
        link: '/correspondances',
        roles: 'USER,ADMIN'
    },
    {
        id: 'utilisateur',
        title: 'utilisateur',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/utilisateur',
        roles: 'USER,ADMIN'
    },
    {
        type: 'divider',
        roles: 'ADMIN,USER,ENTREPRISE,INVITE'
    }
];


defaultNavigation.push(
    {
        type: 'divider'
    })

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        roles: 'ADMIN,USER,ENTREPRISE,INVITE'
    },
    {
        id: 'declarations',
        title: 'Déclaration',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/declarations',
        roles: 'USER,ADMIN,ENTREPRISE'
    },
    {
        id: 'correspondances',
        title: 'Correspondances',
        type: 'basic',
        icon: 'iconsmind:university',
        link: '/correspondances',
        roles: 'USER,ADMIN'
    },
    {
        id: 'utilisateur',
        title: 'utilisateur',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/utilisateur',
        roles: 'USER,ADMIN'
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        roles: 'ADMIN,USER,ENTREPRISE,INVITE'
    },
    {
        id: 'declarations',
        title: 'Déclaration',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/declarations',
        roles: 'USER,ADMIN,ENTREPRISE'
    },
    {
        id: 'correspondances',
        title: 'Correspondances',
        type: 'basic',
        icon: 'iconsmind:university',
        link: '/correspondances',
        roles: 'USER,ADMIN'
    },
    {
        id: 'utilisateur',
        title: 'utilisateur',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/utilisateur',
        roles: 'USER,ADMIN'
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        roles: 'ADMIN,USER,ENTREPRISE,INVITE'
    },
    {
        id: 'declarations',
        title: 'Déclaration',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/declarations',
        roles: 'USER,ADMIN,ENTREPRISE'
    },
    {
        id: 'correspondances',
        title: 'Correspondances',
        type: 'basic',
        icon: 'iconsmind:university',
        link: '/correspondances',
        roles: 'USER,ADMIN'
    },
    {
        id: 'utilisateur',
        title: 'utilisateur',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/utilisateur',
        roles: 'USER,ADMIN'
    },
];

const host = location.hostname
console.log(location);
export const environment = {
    production: true,
    // urlApi:"http://localhost/backend/public/index.php/",
    urlApi:"https://"+host+"/backend/public/index.php/",
    // urlApi:"https://backend.geschool.barasoft-burkina.com/public/index.php/",
    // urlApi:"http://51.178.18.128:8201/"
    // urlApi:"/"
};
